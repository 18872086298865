.main-loader {
        position: fixed;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        width: 200px;
        height: 200px;
        text-align: center;
        -webkit-animation: bounce-in-top 1.1s both;
        animation: bounce-in-top 1.1s both;
}

.main-loader .ball-thing {
        position: absolute;
        left: 25px;
        top: 25px;
        transform: rotate(-50deg);
}

.main-loader p {
        margin-top: 100%;
}

@-webkit-keyframes bounce-in-top {
        0% {
                -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
        }

        38% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
        }

        55% {
                -webkit-transform: translateY(-65px);
                transform: translateY(-65px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        72% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        81% {
                -webkit-transform: translateY(-28px);
                transform: translateY(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        90% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        95% {
                -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }
}

@keyframes bounce-in-top {
        0% {
                -webkit-transform: translateY(-500px);
                transform: translateY(-500px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
                opacity: 0;
        }

        38% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
                opacity: 1;
        }

        55% {
                -webkit-transform: translateY(-65px);
                transform: translateY(-65px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        72% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        81% {
                -webkit-transform: translateY(-28px);
                transform: translateY(-28px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        90% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }

        95% {
                -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
                -webkit-animation-timing-function: ease-in;
                animation-timing-function: ease-in;
        }

        100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        }
}